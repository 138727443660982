import firebase from '../firebase/firebase';
import React, { useState } from 'react';
import * as Icons from 'react-feather';
import Button from '../components/Button';
import SEO from '../components/seo';
import '../scss/contact.scss';
import validateEmailAdress from '../utils/validateEmail';
import { navigate } from "@reach/router";

export interface ContactTemplateProps {
    pageContext: {
        location: string,
        phoneNumber: string,
        email: string
    }
}

const ContactTemplate: React.FC<ContactTemplateProps> = (
    { pageContext: { location: adress, phoneNumber, email: contactEmail } }
) => {
    const [_name, setName] = useState('');
    const [_email, setEmail] = useState('');
    const [_message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);

    function validateForm(): boolean {
        let name = _name.trim();
        let email = _email.trim();
        let message = _message.trim();

        if (!name || !email || !message) return false;
        if (!validateEmailAdress(email)) return false;

        return true;
    }

    async function submitForm() {
        if (!validateForm()) return;

        setLoading(true);

        try {
            await firebase.firestore().collection('send-mail').add({
                to: contactEmail,
                replyTo: _email.trim(),
                message: {
                    subject: `${_name.trim()} has contacted you.`,
                    text: _message,
                    html: `
                        <p>${_message}</p>
                        <br>
                        <p style="margin: 0 0 4px;">${_name}</p>
                        <p style="margin: 0;">${_email}</p>
                    `
                }
            });
        } catch (_) {
            setLoading(false);
            return;
        }

        navigate('/');
        setLoading(false);
    }

    return (
        <div className="contact-form">
            <SEO title="Contact Us" />
            <div className="info-section">
                <h1 className="title">Contact Us</h1>
                <hr className="thick-divider" />
                <p className="subtitle"></p>
                <div className="info">
                    {
                        adress && typeof adress === 'string' && (
                            <div className="info-link">
                                <div className="icon">
                                    <Icons.MapPin />
                                </div>
                                {adress}
                            </div>
                        )
                    }
                    {
                        phoneNumber && typeof phoneNumber === 'string' && (
                            <div className="info-link">
                                <div className="icon">
                                    <Icons.Phone />
                                </div>
                                {phoneNumber}
                            </div>
                        )
                    }
                    {
                        contactEmail && typeof contactEmail === 'string' && (
                            <div className="info-link">
                                <div className="icon">
                                    <Icons.Mail />
                                </div>
                                {contactEmail}
                            </div>
                        )
                    }
                </div>
            </div>
            <div className="spacing" />
            <form className="form-section" onSubmit={(e) => {
                e.preventDefault();
                submitForm();
            }}>
                <div className="form-input">
                    <label>Full Name</label>
                    <input onChange={e => {
                        setName(e.currentTarget.value);
                    }} required id="name" type="text" placeholder="Your Name" />
                </div>
                <div className="form-input">
                    <label>Email</label>
                    <input onChange={e => {
                        setEmail(e.currentTarget.value);
                    }} required id="email" type="email" placeholder="email@example.com" />
                </div>
                <div className="form-input">
                    <label>Message</label>
                    <textarea onChange={e => {
                        setMessage(e.currentTarget.value);
                    }} required id="message" placeholder="Hello World" />
                </div>
                <br className="spacing" />
                <div className="form-submit">
                    <Button type="submit" trailing={(<Icons.Send size={20} />)}>
                        Submit
                    </Button>
                </div>
            </form>
            <div style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100vw',
                height: '100vh',
                zIndex: 10,
                background: 'rgba(255, 255, 255, 0.85)',
                opacity: loading ? 1 : 0,
                visibility: loading ? 'visible' : 'hidden',
                transition: 'opacity 0.2s, visibility 0.2s'
            }}></div>
        </div>
    )
}

export default ContactTemplate;