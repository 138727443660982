import React from 'react';

import '../scss/button.scss';

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    className?: string,
    id?: string,
    onClick?: () => void,
    outline?: boolean,
    block?: boolean,
    big?: boolean,
    trailing?: JSX.Element
}

const Button: React.FC<ButtonProps> = (props) => {
    const { className, id, onClick, outline, block, big, children, trailing } = props;

    return (
        <div className={className} id={id}>
            <button
                {...{
                    ...props,
                    big: undefined,
                    block: undefined,
                    outline: undefined,
                    trailing: undefined
                }}
                onClick={onClick}
                tabIndex={0}
                id=""
                className={`button${block ? ' block' : ''}${big ? ' big' : ''}${outline ? ' outline' : ''}`}>
                {
                    trailing && (
                        <div className="icon spacer">
                            {trailing}
                        </div>
                    )
                }
                {children}
                {
                    trailing && (
                        <div className="icon trailing">
                            {trailing}
                        </div>
                    )
                }
            </button>
        </div>
    )
}

export default Button;